<template>
<h1>Events</h1>

<hr>

<h3>Gratitude Day</h3>
Saturday, November 2nd, 2024<br>
In-person & Online<br>
More details coming soon!<br>
<br>
<hr>

</template>

<style scoped>
 img {
   width: 80vw;
   max-width: 800px
 }

h2, h3{ 
  text-align: center;
}

a {
  font-size: 24px;
}

</style>