<template>
<h1>Events</h1>
<hr>
<br>
<h2>Gratitude Day Registration</h2>
<br>
<form name="gratitude_day" method="POST" data-netlify="true" data-netlify-honeypot="bot-field">
  <input type="hidden" name="hidden-form" value="hidden" >
  <label>First Name + Last Initial: </label>
  <input type="text" name="Name" required>
  <br><br>
  <label>Email Address (You will receive the zoom link here if attending on Zoom):</label>
  <input type="text" name="Email" required>
  <br><br>
  <label>Attendance Method:</label><br>
  <input type="radio" name="Attendance" value="In Person" required>
  <label>In person</label><br>
  <input type="radio" name="Attendance" value="Zoom" required>
  <label>Zoom</label><br>
  <br>
  <label>Eating lunch at the event (If attending in person):</label><br>
  <input type="radio" name="Lunch" value="Yes" required>
  <label>Yes</label><br>
  <input type="radio" name="Lunch" value="No" required>
  <label>No</label><br>

  <p>If you are eating lunch at the event, please send $10 to intergroup with a note that it is for Gratitude Day Lunch. You can also pay in person.</p>
  <p> You can send your lunch money on Venmo to: @Intergroup-CASA<br>
      Or by mail to: CASA Intergroup, P.O. Box 77461, Washington DC 20013-8461</p>
  <br>
  <input name="submit" type="submit" value="Submit" class="button-46">
  <br><br>
</form>
</template>

<style scoped>
 img {
   width: 600px
 }

form { 
  text-align: left;
}

</style>